import React from "react";

//packages
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

//components
import ReusableButton from "../../general/ReusableButton";

export default function ResellerReg() {
    return (
        <MainContainer>
            <SubContainer className="wrapper">
                <Container to={"/#home-spot"}>
                    <LeftContainer>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/reseller-businessman.png"
                            }
                            alt="Reseller"
                        />
                    </LeftContainer>
                    <RightContainer>
                        <Title>Reseller Registration</Title>
                        <Description>
                            Sell your creative works, handmade items, crafts at the Dett platform by
                            registering yourself as a Reseller. Take your business online today.
                        </Description>
                        <GiftNowButton to={"#home-spot"}>
                            <ReusableButton
                                width={"medium"}
                                color={"pink"}
                                title={"Register now"}
                            />
                        </GiftNowButton>
                    </RightContainer>
                </Container>
            </SubContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding-bottom: 80px;
    @media all and (max-width: 1280px) {
        padding-bottom: 60px;
    }
    @media all and (max-width: 1080px) {
        padding-bottom: 30px;
    }
`;
const SubContainer = styled.div``;
const Container = styled(HashLink)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    overflow: hidden;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        padding: 30px;
    }
    @media all and (max-width: 360px) {
        padding: 20px;
    }
`;
const RightContainer = styled.div`
    width: 56%;
    padding-right: 30px;
    @media all and (max-width: 1080px) {
        width: 100%;
        padding-right: 0px;
    }
`;
const Title = styled.h3`
    color: #282c3f;
    font-size: 32px;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 26px;
    }
    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    color: #777a86;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
const GiftNowButton = styled(HashLink)`
    display: inline-block;
    margin-top: 20px;
`;
const LeftContainer = styled.div`
    width: 40%;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    @media all and (max-width: 1080px) {
        margin: 0 auto 20px;
        width: 60%;
        img {
            border-radius: 16px;
        }
    }
    @media all and (max-width: 768px) {
        width: 80%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
