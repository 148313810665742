import React from "react";

//packages
import styled from "styled-components";

//components
import ReusableButton from "../../../general/ReusableButton";

function Banner() {
    //navigate to prebook section
    const NavigateTo = () => {
        window.open("/prebook", "_blank");
    };
    return (
        <>
            <MainContainer>
                <Wrapper className="wrapper">
                    <ContentBox>
                        <LeftBox>
                            <Heading>Start Gifting Now !</Heading>
                            <Paragraph>
                                Grow your business through corporate gifting & change your company
                                lifestyle
                            </Paragraph>
                            <MiddleBox>
                                <Feature>
                                    <TickImage>
                                        <img
                                            src={
                                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/tick-white.svg"
                                            }
                                            alt="tick"
                                        />
                                    </TickImage>
                                    <Title>Grow Your Business</Title>
                                </Feature>
                                <span className="pipe"></span>
                                <Feature>
                                    <TickImage>
                                        <img
                                            src={
                                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/tick-white.svg"
                                            }
                                            alt="tick"
                                        />
                                    </TickImage>
                                    <Title>100% Employee Satisfaction</Title>
                                </Feature>
                            </MiddleBox>

                            <ReusableButton
                                onClickFunction={NavigateTo}
                                color="white"
                                title={"Gift Now"}
                            />
                        </LeftBox>
                        <RightBox>
                            <BannerImage>
                                <img
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/banner-image.png"
                                    }
                                    alt="banner"
                                />
                            </BannerImage>
                        </RightBox>
                    </ContentBox>
                </Wrapper>
            </MainContainer>
        </>
    );
}

export default Banner;

const MainContainer = styled.section`
    padding: 50px 0;
    @media all and (max-width: 768px) {
        padding: 30px 0;
    }
`;
const Wrapper = styled.section`
    @media all and (max-width: 768px) {
        width: 85%;
    }
`;
const ContentBox = styled.section`
    padding: 40px;
    background: linear-gradient(90deg, #e7578d 12.58%, #eb5b92 40.18%, #ffffff 113.56%);
    border-radius: 16px;
    min-height: 250px;
    position: relative;
    @media all and (max-width: 768px) {
        padding: 30px;
        background: linear-gradient(90deg, #e7578d 12.58%, #eb5b92 40.18%, #ffffff 150.56%);
    }
`;
const LeftBox = styled.div`
    width: 65%;
    @media all and (max-width: 1080px) {
        width: 75%;
    }

    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const Heading = styled.h1`
    color: #fff;
    margin-bottom: 25px;
    font-family: "dm_sansboldmedium";
    font-size: 35px;
    @media all and (max-width: 980px) {
        font-size: 30px;
        margin-bottom: 15px;
    }

    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 648px) {
        font-size: 26px;
    }

    @media all and (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 10px;
    }

    @media all and (max-width: 360px) {
        font-size: 26px;
    }
`;
const Paragraph = styled.p`
    color: #fff;
    font-family: "dm_sansregular";
    font-size: 16px;
    line-height: 1.4em;
    @media all and (max-width: 1280px) {
        width: 85%;
    }

    @media all and (max-width: 980px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;
const MiddleBox = styled.div`
    display: flex;
    align-items: center;
    margin: 25px 0;
    span.pipe {
        display: inline-block;
        margin: 0 15px;
        width: 1px;
        height: 25px;
        background-color: #fff;
        @media all and (max-width: 640px) {
            display: none;
        }
    }
    @media all and (max-width: 768px) {
        margin: 16px auto;
    }

    @media all and (max-width: 640px) {
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
        width: max-content;
    }
`;

const Feature = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;
const TickImage = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    padding: 4px;
    height: 20px;
    border-radius: 50%;
    background-color: #0dc59b;
    margin-right: 10px;
    font-family: "dm_sansregular";
`;

const Title = styled.h4`
    color: #fff;

    font-size: 17px;
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;

const RightBox = styled.div`
    position: absolute;
    right: 25px;
    bottom: 0px;
    @media all and (max-width: 1200px) {
        right: 0;
    }

    @media all and (max-width: 980px) {
        display: none;
    }
`;

const BannerImage = styled.div`
    width: 350px;
    @media all and (max-width: 1280px) {
        width: 370px;
    }

    @media all and (max-width: 1180px) {
        width: 300px;
    }

    @media all and (max-width: 1080px) {
        width: 270px;
    }
`;
