import React, { useEffect, useState } from "react";

import thumnailImage from "../../assets/images/thumbnail.svg";

export default function Img({ imgSrc, height, width, marginleft }) {
    const [usedSrc, setUsedSrc] = useState(thumnailImage);
    const [usedEffectStyle, setUsedEffectStyle] = useState({
        filter: "blur(0px)",
        clipPath: "inset(0)",
    });

    useEffect(() => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            setUsedSrc(img.src);
            setUsedEffectStyle({});
        };
    }, [imgSrc]);

    return (
        <img
            alt="thumbnail"
            src={usedSrc}
            width={width}
            height={height}
            style={{
                transition: "filter 0.1s ease-out",
                marginLeft: marginleft,
                ...usedEffectStyle,
            }}
        />
    );
}
