import React from "react";
import styled from "styled-components";

export default function PostModal({ imageModal, setImageModal, selectedPost, setSelectedPost }) {
    const handleModal = () => {
        setImageModal(false);
        setSelectedPost({});
    };

    return (
        <MainContainer>
            {imageModal ? <Overlay onClick={() => handleModal()}></Overlay> : ""}
            <BackContainer style={{ transform: imageModal && "scale(1,1)" }}>
                <Modal type="textarea">
                    {selectedPost?.media_url && (
                        <img
                            src={selectedPost?.media_url}
                            type="image"
                        />
                    )}

                    <CloseButtonContainer onClick={() => handleModal()}>
                        <CloseButton
                            src="https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/closebutton.svg"
                            alt="close"
                        />
                    </CloseButtonContainer>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div``;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    max-width: 590px;
    width: 90%;
    margin: 0 auto;
    height: 100vh;
    right: 0;
    left: 0;
    top: 0px;
    z-index: 1000;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 650px;
    margin: 0 auto;
    /* padding: 20px 30px; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #fff;

    @media all and (max-width: 1280px) {
    }

    @media all and (max-width: 1150px) {
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 980px) {
    }
    @media all and (max-width: 768px) {
    }

    @media all and (max-width: 640px) {
    }

    @media all and (max-width: 600px) {
    }

    @media all and (max-width: 550px) {
    }

    @media all and (max-width: 480px) {
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }

    source {
        @media all and (max-width: 640px) {
            width: 70%;
        }
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: -32px;
    right: 0px;
    cursor: pointer;
    filter: invert(1);
    background: #000;
`;

const CloseButton = styled.img`
    width: 100%;
    display: block;
`;
