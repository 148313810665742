import React, { useContext, useEffect } from "react";

//packages
import { useNavigate } from "react-router";
import styled from "styled-components";

//contest
import { Context } from "../../Contexts/Store";

const close =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/closebutton.svg";

function SessionExpireModal({ sessionModal, setSessionModal }) {
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();

    //preventing outside scroll when modal enabled
    useEffect(() => {
        const body = document.body;
        if (sessionModal) {
            body.classList.add("overflow");
        }
        return () => {
            body.classList.remove("overflow");
        };
    }, [sessionModal]);

    //modal closing function
    const handleModal = () => {
        setSessionModal(false);
        logout();
    };

    //logout function
    const logout = () => {
        dispatch({
            type: "LOGOUT",
        });
        navigate("/");
    };

    //login function
    const login = () => {
        dispatch({
            type: "LOGOUT",
        });
        window.location.href = "/login";
    };

    return (
        <MainContainer>
            {sessionModal ? <Overlay onClick={() => handleModal()}></Overlay> : ""}
            <BackContainer style={{ transform: sessionModal && "scale(1,1)" }}>
                <Modal>
                    <Title>Your session has expired, Please login</Title>
                    <LoginButton onClick={() => login()}>Login</LoginButton>
                    <CloseButtonContainer onClick={() => handleModal()}>
                        <CloseButton
                            onClick={() => logout()}
                            src={close}
                            alt="close"
                        />
                    </CloseButtonContainer>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default SessionExpireModal;

const MainContainer = styled.div``;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    height: 100vh;
    right: 0;
    left: 0;
    top: 0px;
    z-index: 1000;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    min-height: 300px;
    padding: 30px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 360px) {
        width: 100%;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
`;

const Title = styled.h2`
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    font-family: "dm_sansregular";
`;

const LoginButton = styled.span`
    border-radius: 5px;
    background-color: #ff7d6c;
    color: #fff;
    padding: 7px 35px;
    font-family: "dm_sansboldmedium";
    cursor: pointer;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #d7d1d1;
    border-radius: 50%;
    padding: 5px;
    border: 1.5px solid #fff;
`;

const CloseButton = styled.img`
    width: 100%;
    display: block;
`;
