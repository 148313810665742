import React from "react";

//packages
import styled from "styled-components";

function EmptyOrders() {
    return (
        <MainContainer>
            <Wrapper className="wrapper">
                <ContentBox>
                    <EmptyOrderImage>
                        <img
                            src={
                                require("../../../assets/images/empty-pages/empty-order.svg")
                                    .default
                            }
                            alt="empty-cart"
                        />
                    </EmptyOrderImage>
                    <Title>You haven’t placed any order yet ! </Title>
                    <Paragraph>After placing order, you can track from here! </Paragraph>
                </ContentBox>
            </Wrapper>
        </MainContainer>
    );
}

export default EmptyOrders;

const MainContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;
    background: #f8f8f8;
    border-radius: 5px;
    overflow: hidden;
`;
const Wrapper = styled.section``;
const ContentBox = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const EmptyOrderImage = styled.div`
    width: 250px;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        width: 200px;
    }
`;
const Title = styled.h2`
    font-size: 20px;
    color: #282c3f;
    text-align: center;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin-bottom: 30px;
    color: #777a86;
    font-family: "dm_sansregular";
    @media all and (max-width: 360px) {
        width: 80%;
        text-align: center;
    }
`;
