import React, { useState } from "react";

//packages
import styled from "styled-components";
import { Fade } from "react-reveal";

function Features() {
    //states
    const [hover, setHover] = useState("");
    return (
        <div>
            <MainContainer className="wrapper">
                <Fade
                    bottom
                    cascade
                >
                    <TitleDiv>Our Features</TitleDiv>
                    <Description>
                        We focus on creating a touchpoint, emotional connection with employees,
                        clients, or prospects through the use of gifts to strengthen your bonding
                        with your loved ones
                    </Description>

                    <FeatureDiv
                        onMouseEnter={() => setHover("1")}
                        onMouseLeave={() => setHover("")}
                    >
                        <LeftDiv>
                            <ImageConatiner>
                                <ImageComponents
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/featureanimation.svg"
                                    }
                                    alt="feature"
                                    className={hover == "1" ? "hover" : ""}
                                />
                            </ImageConatiner>
                        </LeftDiv>
                        <RightDiv className={hover == "1" ? "active" : ""}>
                            <RightTitle>Instant gifting</RightTitle>
                            <RightDiscription>
                                Instant booking of top branded gifts at affordable prices with
                                expert guidance is a unique feature of ours.
                            </RightDiscription>
                        </RightDiv>
                    </FeatureDiv>
                    <FeatureDivReserve
                        onMouseEnter={() => setHover("2")}
                        onMouseLeave={() => setHover("")}
                    >
                        <LeftDivReverse className={hover == "2" ? "active" : ""}>
                            <LeftTitle>Pre-booking</LeftTitle>
                            <LeftDescribtion>
                                You can pre-book the special occasions of your loved one's before
                                365 days advancely to surprise them with lovely gifts from the Dett.
                            </LeftDescribtion>
                        </LeftDivReverse>
                        <RightDivReverse>
                            <ImageConatiner>
                                <ImageComponents
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/featureanimation-two.svg"
                                    }
                                    alt="two"
                                    className={hover == "2" ? "hover" : ""}
                                />
                            </ImageConatiner>
                        </RightDivReverse>
                    </FeatureDivReserve>
                    <FeatureDiv
                        onMouseEnter={() => setHover("3")}
                        onMouseLeave={() => setHover("")}
                    >
                        <LeftDiv>
                            <ImageConatiner>
                                <ImageComponents
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/featureanimation-three.svg"
                                    }
                                    alt="three"
                                    className={hover == "3" ? "hover" : ""}
                                />
                            </ImageConatiner>
                        </LeftDiv>
                        <RightDiv className={hover == "3" ? "active" : ""}>
                            <RightTitle>Reliability</RightTitle>
                            <RightDiscription>
                                Most trusted gifting platform. Client's information is confidential.
                                Emphasize unique values, strong vision & customer-centric approach.
                            </RightDiscription>
                        </RightDiv>
                    </FeatureDiv>
                </Fade>
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div`
    background-image: url(${"https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/arcimage.png"});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 60% 74%;
    background-size: 82%;

    @media all and (max-width: 1380px) {
        background-position: 60% 71%;
    }

    @media all and (max-width: 1330px) {
        background-position: 60% 69%;
    }

    @media all and (max-width: 1200px) {
        background-image: none;
    }
    @media all and (max-width: 480px) {
        padding: unset;
    }
    &.wrapper {
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }
`;
const TitleDiv = styled.h1`
    color: #282c3f;
    font-size: 38px;
    font-family: "dm_sansboldbold";
    text-align: center;
    margin-bottom: 15px;
    @media all and (max-width: 640px) {
        font-size: 35px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const Description = styled.p`
    color: #777a86;
    font-size: 15px;
    line-height: 20px;
    width: 50%;
    text-align: center;
    font-family: "dm_sansboldmedium";
    margin: 0 auto;
    margin-bottom: 50px;
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const ImageConatiner = styled.div`
    width: 75%;
    @media all and (max-width: 640px) {
        width: 98%;
    }
`;
const FeatureDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    padding: 40px 45px;
    background: #fcfcfc;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 30px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        padding: 30px;
        justify-content: space-between;
    }
`;
const LeftDiv = styled.div`
    width: 20%;
    @media all and (max-width: 480px) {
    }
`;

const ImageComponents = styled.img`
    width: 100%;
    display: block;
    &.hover {
        transform: scale(1.1);
        transition: 0.4s ease;
    }
`;
const RightDiv = styled.div`
    width: 80%;
    &.active {
        transition: 1s ease;
        transform: translateX(15px);
    }
    @media all and (max-width: 640px) {
        /* width: 100%; */
        margin-left: 15px;
    }
    @media all and (max-width: 480px) {
        width: 70%;
    }
`;
const RightTitle = styled.h3`
    color: #282c3f;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    font-size: 18px;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const LeftDescribtion = styled.p`
    font-size: 15px;
    line-height: 20px;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 480px) {
        font-size: 13px;
    }

    color: #777a86;
`;
const LeftTitle = styled.h3`
    color: #282c3f;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    font-size: 18px;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const RightDiscription = styled.p`
    font-size: 15px;
    line-height: 22px;
    font-family: "dm_sansboldmedium";

    color: #777a86;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const RightDivReverse = styled.div`
    width: 20%;
    @media all and (max-width: 480px) {
    }
`;
const LeftDivReverse = styled.div`
    width: 80%;
    &.active {
        transform: translateX(-20px);
        transition: 0.5s ease;
    }
    @media all and (max-width: 640px) {
        /* width: 100%; */
        margin-right: 15px;
    }
    @media all and (max-width: 480px) {
        width: 70%;
    }
`;
const FeatureDivReserve = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    padding: 40px 45px;
    background: #fcfcfc;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 30px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        justify-content: space-between;
        padding: 30px;
    }
`;

export default Features;
