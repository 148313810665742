import React from "react";

//packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

//components //future
import ReusableButton from "../../general/ReusableButton";

function HomeSurprise() {
    const navigate = useNavigate();
    //navigate to prebook section
    const NavigateTo = () => {
        navigate("/prebook");
    };
    return (
        <>
            <MainContainer>
                <SubContainer className="wrapper">
                    <Container onClick={NavigateTo}>
                        <LeftContainer>
                            <Title>Surprise your friends</Title>
                            <Description>
                                Your gift boutique for tailor-made, pre-curated and exquisite gifts
                                & hampers for your loved ones. Made with love, sealed with a bow!,
                                Keep loving, Keep gifting!
                            </Description>

                            <ReusableButton
                                onClickFunction={NavigateTo}
                                color="pink"
                                title={"Gift now"}
                            />
                        </LeftContainer>

                        <RightContainer>
                            <img
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/home-surprise.png"
                                }
                                alt="gift-boxes"
                            />
                        </RightContainer>
                    </Container>
                </SubContainer>
            </MainContainer>
            <Outlet />
        </>
    );
}

const MainContainer = styled.div`
    padding: 100px 0 0;
    @media all and (max-width: 1280px) {
        padding: 50px 0 0;
    }
    @media all and (max-width: 1080px) {
        padding: 30px 0 0;
    }
`;
const SubContainer = styled.div``;
const Container = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    border-radius: 16px;
    @media all and (max-width: 1280px) {
        flex-wrap: wrap;
        padding: 30px;
    }
`;
const LeftContainer = styled.div`
    width: 48%;
    padding: 25px 0 25px 40px;
    @media all and (max-width: 1280px) {
        width: 100%;
        order: 2;
        padding: 0;
    }
`;
const Title = styled.h3`
    color: #282c3f;
    font-size: 32px;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    color: #777a86;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const GiftNowButton = styled(Link)`
    display: inline-block;
    background: #f36097;
    border-radius: 8px;
    padding: 12px 24px;
    color: #fff;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    /* box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07); */
    @media all and (max-width: 1280px) {
        margin: 0 auto 20px;
    }
    @media all and (max-width: 980px) {
        width: 70%;
    }
    @media all and (max-width: 768px) {
        width: 80%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;

export default HomeSurprise;
