import React from "react";

//package
import Lottie from "react-lottie";

//json file for button
import Loader from "../../../assets/lottie/buttonLoader.json";

export default function ButtonLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader,
        rendererSettings: {},
    };
    return (
        <Lottie
            options={defaultOptions}
            height={50}
            width={50}
        />
    );
}
