import React, { useState, useEffect } from "react";

//packages
import styled from "styled-components";

//axios
import { dettConfig } from "../../../../axiosConfig";

//components
import CareerDataLoader from "../../../general/loader/CareerDataLoader";
import Img from "../../../general/Img";

//images

function Occassions({ occasions }) {
    //images
    const gift1 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-1.svg";
    const gift2 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-2.svg";
    const gift3 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-3.svg";
    const gift4 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-4.svg";
    const gift5 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-5.svg";
    const gift6 =
        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-6.svg";
    //repeating gift images
    const giftImages = [gift1, gift2, gift3, gift4, gift5, gift6];

    //states
    const [gifts, setGifts] = useState([]);
    const [occassionId, setOccassionId] = useState(null);
    const [id, setId] = useState([]);
    const [loading, setLoading] = useState(false);

    //dropdown function
    const handleDropdown = (occassionId) => {
        setOccassionId(occassionId);
        if (!id.includes(occassionId)) {
            setId([...id, occassionId]);
        } else {
            setId(id.filter((item) => item !== occassionId));
        }
    };

    //gifts fetching api
    useEffect(() => {
        if (occassionId) {
            setLoading(true);
            dettConfig
                .get(`api/v1/bookings/corporate-gifting/occasion/${occassionId}/`)
                .then((response) => {
                    const { data, StatusCode } = response?.data;
                    if (StatusCode === 6000) {
                        setGifts(data.gallery);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });

            return () => {
                setGifts(null);
            };
        }
    }, [occassionId]);

    return (
        <>
            <MainContainer>
                <Wrapper className="wrapper">
                    <ContentBox>
                        <TopBox>
                            <MainHaeding>Occasions Gifted</MainHaeding>
                            <Paragraph>
                                As a token of appreciation and love for your hardworking and
                                dedicated employees, start gifting to express your gratitude and to
                                motivate them.
                            </Paragraph>
                        </TopBox>
                        <SliderBox occasion={occasions}>
                            {occasions?.map((occasion, index) => (
                                <OccassionCard
                                    onClick={() => handleDropdown(occasion?.id)}
                                    key={occasion?.id}
                                >
                                    <LeftContainer>
                                        <LeftBox>
                                            <GiftImage>
                                                <img
                                                    src={giftImages[index % giftImages.length]}
                                                    alt={`gifts ${index}`}
                                                />
                                            </GiftImage>
                                            <Title>{occasion?.title}</Title>
                                            <Description>{occasion?.description}</Description>
                                        </LeftBox>

                                        <SmallRightArrow
                                            className={id.includes(occasion?.id) && "rotate"}
                                            onClick={() => handleDropdown(occasion?.id)}
                                        >
                                            <img
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/small-orange-right-arrow.svg"
                                                }
                                                alt="right-arrow"
                                            />
                                        </SmallRightArrow>
                                    </LeftContainer>

                                    <DropDownBox
                                        onClick={(e) => e.stopPropagation()}
                                        className={id.includes(occasion?.id) ? "animate" : ""}
                                    >
                                        <Border></Border>
                                        <Heading>
                                            Birthday <span>Gift’s</span>
                                        </Heading>
                                        <GiftsContainer>
                                            {loading ? (
                                                <CareerDataLoader />
                                            ) : (
                                                gifts?.map((gift) => (
                                                    <Gift key={gift?.id}>
                                                        <Img
                                                            imgSrc={gift?.image}
                                                            height={"100%"}
                                                            width={"100%"}
                                                        />
                                                    </Gift>
                                                ))
                                            )}
                                        </GiftsContainer>
                                    </DropDownBox>
                                </OccassionCard>
                            ))}
                        </SliderBox>
                    </ContentBox>
                </Wrapper>
            </MainContainer>
        </>
    );
}

export default Occassions;

const MainContainer = styled.section`
    padding: 30px 0 40px 0;
    background-color: #fff;
    z-index: 50;

    @media all and (max-width: 1280px) {
        padding-bottom: 30px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 20px;
    }
`;
const Wrapper = styled.section`
    @media all and (max-width: 768px) {
        width: 85%;
    }
`;
const ContentBox = styled.section``;
const TopBox = styled.div``;
const MainHaeding = styled.h1`
    color: #282c3f;
    margin-bottom: 15px;
    text-align: center;
    font-family: "dm_sansboldbold";
    font-size: 42px;
    @media all and (max-width: 1080px) {
        font-size: 40px;
    }

    @media all and (max-width: 980px) {
        font-size: 38px;
    }

    @media all and (max-width: 768px) {
        font-size: 35px;
    }

    @media all and (max-width: 680px) {
        font-size: 32px;
    }

    @media all and (max-width: 640px) {
        font-size: 30px;
    }

    @media all and (max-width: 480px) {
        font-size: 28px;
    }

    @media all and (max-width: 360px) {
        font-size: 26px;
    }
`;
const Paragraph = styled.p`
    color: #777a86;
    font-size: 16px;
    text-align: center;
    font-family: "dm_sansregular";
    width: 55%;
    margin: 0 auto;
    line-height: 1.4em;
    @media all and (max-width: 1300px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        font-size: 14px;
    }
`;

//slider section styles
const SliderBox = styled.div`
    margin-top: 40px;
    width: 80%;
    margin: 40px auto 0;
    background-color: #fff;
    @media all and (max-width: 980px) {
        width: 90%;
    }

    @media all and (max-width: 680px) {
        width: 100%;
    }
`;
const OccassionCard = styled.div`
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 0px 100px rgba(131, 131, 131, 0.08);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 61px rgba(77, 77, 77, 0.14);
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 768px) {
        border-radius: 0;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LeftBox = styled.div``;

const GiftImage = styled.div`
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
`;
const Title = styled.h2`
    margin-bottom: 10px;
    color: #282c3f;
    font-size: 18px;
    font-family: "dm_sansboldbold";
`;
const Description = styled.p`
    margin-bottom: 15px;
    font-size: 16px;
    color: #777a86;
    font-family: "dm_sansregular";
    line-height: 1.4em;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;

const SmallRightArrow = styled.span`
    display: inline-block;
    min-width: 35px;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 4px 61px rgba(77, 77, 77, 0.14);
    &.rotate {
        transition: all 0.4s ease-in-out;
        transform: rotate(90deg);
    }
    img {
        box-shadow: 0px 4px 61px rgba(77, 77, 77, 0.14);
    }
`;

//GIFTS DROPDOWN - STYLES
const DropDownBox = styled.div`
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    opacity: 0.3;
    &.animate {
        max-height: 550px;
        opacity: 1;
        transition: all 0.4s ease-in-out;
        overflow-y: scroll;
    }
`;

const Border = styled.hr`
    height: 1px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 15px 0;
`;

const Heading = styled.h2`
    margin-bottom: 10px;
    color: #282c3f;
    font-size: 18px;
    font-family: "dm_sansboldbold";
    span {
        color: #ff5e48;
    }
`;
const GiftsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #fff;
    margin-top: 20px;
    @media all and (max-width: 480px) {
        gap: 12px;
    }
`;
const Gift = styled.div`
    border-radius: 5px;
    overflow: hidden;
    width: 31.7%;
    @media all and (max-width: 1280px) {
        width: 30.5%;
    }
    @media all and (max-width: 768px) {
        width: 47%;
    }
`;
