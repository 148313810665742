import React, { useContext, useEffect, useState } from "react";

//packages
import styled from "styled-components";
import moment from "moment/moment";
import { Link } from "react-router-dom";

//axios
import { dettConfig } from "../../../axiosConfig";

//contest
import { Context } from "../../Contexts/Store";

//components
import VerifyOtpModal from "../../includes/modal/VerifyOtpModal";
import ButtonLoader from "../../general/loader/ButtonLoader";

//custom-hook
import useScreenWidth from "../../general/hooks/useScreenWidth";

export default function Profile() {
    //user data states
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [defaultGender, setDefaultGender] = useState("");
    const [dob, setDob] = useState("");
    const [email, setEmail] = useState("");
    const [userPhoneNumber, setUserPhoneNumber] = useState("");

    //email verification modal states
    const [viewOtpModal, setViewOtpModal] = useState(false);
    // const [emailVerified, setEmailVerified] = useState(false);

    //states for showing,hiding of save,edit,cancel buttons
    const [isEdit, setEdit] = useState(null);
    const [saveEdit, setSaveEdit] = useState(null);

    //button-loader states
    const [saveBtnloader, setSaveBtnLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [mailLoader, setMailLoader] = useState(false);

    //error message states
    const [emailErrMsg, setEmailErrMsg] = useState("");
    const [mailError, setMailError] = useState(false);
    const [dobErrMsg, setDobErrMsg] = useState("");
    const [firstNameErr, setFirstNameErr] = useState("");

    //state for hiding save button in mobile screen
    const [saved, setSaved] = useState("");

    const {
        dispatch,
        state: { user_data, account_details },
    } = useContext(Context);

    const access_token = user_data?.access_token;
    const email_verified = account_details?.email_verified;
    const screenWidth = useScreenWidth();

    //fetching userdata(fname, lname, dob, email , gender etc)
    useEffect(() => {
        if (access_token) {
            dettConfig
                .get("api/v1/accounts/prebook/profile/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { data, StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setFirstName(data?.first_name);
                        if (
                            data?.last_name === null ||
                            data?.last_name === "" ||
                            data.last_name === undefined
                        ) {
                            setLastName("");
                        } else {
                            setLastName(data?.last_name);
                        }
                        if (
                            data?.email === null ||
                            data?.email === "" ||
                            data.email === undefined
                        ) {
                        } else {
                            setEmail(data?.email);
                        }

                        setDob(moment(data?.dob).format("DD-MM-YYYY"));
                        setUserPhoneNumber(data?.phone);
                        setGender(data?.gender);
                        setDefaultGender(data?.gender);

                        dispatch({
                            type: "UPDATE_ACCOUNT_DETAILS",
                            account_details: {
                                first_name: data?.first_name,
                                last_name: data?.last_name ? data?.last_name : "",
                                first_letters_of_name: `${data?.first_name?.charAt(0)}${
                                    data?.last_name ? data.last_name.charAt(0) : ""
                                }`,
                            },
                        });
                    } else {
                    }
                })
                .catch((error) => {});
        }
    }, [access_token, saveEdit]);

    //setting firstname from input
    const handleFirstnameChane = (e) => {
        setFirstNameErr("");
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setFirstName(value.trimStart());
        }
    };

    //setting lastname from input
    const handleLastnameChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setLastName(value?.trimStart());
        }
    };

    //formatting date(DOB) [adding automatic dash b/w DD-MM-YYYY]
    const handleDobChange = (e) => {
        setDobErrMsg("");
        let value = e.target.value;
        var numbers = /^[0-9!@#$%^&*()-?":{}|<>{/}]+$/;
        if (value.match(numbers)) {
            let typedDate =
                value.length === 3 && !value.includes("-")
                    ? `${value.substring(0, 2)}-${value.substring(2)}`
                    : value.length === 6 && value[value.length - 1] !== "-"
                    ? `${value.substring(0, 5)}-${value.substring(5)}`
                    : value;
            setDob(typedDate);
        } else {
            setDob("");
            return false;
        }
    };

    //email validation
    const handleEmailChange = (e) => {
        setEmailErrMsg("");
        setMailError(false);
        setMailLoader(false);
        let value = e.target.value.trimStart();
        const englishOnlyRegex = /^[a-z0-9@.]*$/;
        if (englishOnlyRegex.test(value)) {
            setEmail(value);
        }
    };

    //gender selection and gender field enabling
    const handleGenderChange = async (selectedGender) => {
        if (isEdit === "USER_INFO") {
            setGender(selectedGender);
        }

        if (screenWidth <= 768) {
            if (gender !== selectedGender) {
                setEdit("GENDER_CHANGED");
                setGender(selectedGender);
                setDefaultGender(gender);
                setSaved("SAVED");
            }
        }
    };

    useEffect(() => {
        mailError && setViewOtpModal(false);
    }, [mailError]);

    //name validation
    const validateName = () => {
        var error = "";
        if (firstName === "") {
            setFirstNameErr("This field is required");
            error = "true";
        } else {
            setFirstNameErr("");
            error = "";
        }
        return error;
    };

    //dob validation
    const validateDate = () => {
        var error = "";
        if (dob === "") {
            setDobErrMsg("This field is required");
            error = "true";
        } else if (dob) {
            if (!moment(dob, "DD-MM-YYYY").isValid() || dob.length < 10) {
                setDobErrMsg("Enter a valid date");
                error = "true";
            } else if (moment(dob).isAfter(new Date())) {
                setDobErrMsg("You can't enter future date");
                error = "true";
            } else {
                setDobErrMsg("");
                error = "";
            }
        } else {
            setDobErrMsg("");
            error = "";
        }
        return error;
    };

    //userdata updating api
    const updateProfile = async (field, action) => {
        //enabling button loader in responsive
        if (field) {
        } else {
            setBtnLoader(true);
        }
        //enabling loader for save button
        field === "USER_INFO" && setSaveBtnLoader(true);

        const nameErr = validateName();
        const dobErr = validateDate();

        nameErr !== "true" &&
            dobErr !== "true" &&
            (await dettConfig
                .post(
                    "api/v1/accounts/prebook/edit/profile/",
                    {
                        first_name: firstName,
                        last_name: lastName,
                        gender: gender,
                        dob: moment(dob, "DD-MM-YYYY", true).format("YYYY-MM-DD"),
                    },

                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setSaveBtnLoader(false);
                        setBtnLoader(false);

                        if (field === "USER_INFO") {
                            //disabling field after editing
                            setEdit(null);
                        }

                        if (action === "CLOSE_SAVE_BUTTON") {
                            if (btnLoader === false) {
                                //closing save button(bottom) in responsive
                                setSaved("");
                                setEdit(null);
                            }
                        }

                        dispatch({
                            type: "UPDATE_ACCOUNT_DETAILS",
                            account_details: {
                                gender: gender,
                                first_name: firstName,
                                last_name: lastName ? lastName : "",
                                first_letters_of_name: `${firstName?.charAt(0)}${lastName?.charAt(
                                    0
                                )}`,
                            },
                        });
                    }
                })
                .catch((error) => {
                    setSaveBtnLoader(false);
                    setBtnLoader(false);
                }));
        setSaveBtnLoader(false);
        setBtnLoader(false);
    };

    //edit data function(mobile device)
    const EditUserInfo = (fieldToEdit) => {
        if (screenWidth < 768) {
            setEdit(fieldToEdit);
        }
    };

    //enabling / disabling & highlighting firtsname, lastname, Dob fields
    const enableFields = (field) => {
        var readOnly = true;
        //enabling fields when screen size is > 768px
        if (screenWidth > 768) {
            if (isEdit !== "USER_INFO" || saveEdit !== "EDIT_USERINFO") {
                readOnly = true;
            } else {
                readOnly = false;
            }
        }
        //enabling fields on it in mobile screen
        if (screenWidth < 768) {
            if (isEdit !== field) {
                readOnly = true;
            } else {
                readOnly = false;
            }
        }
        return readOnly;
    };

    //editing state of enabling input fields when edit button is clicked
    const editAllField = (type) => {
        setEdit(type);
        setSaveEdit("EDIT_USERINFO");
    };

    //enabling each input field when clicked on those field in  mobile screens
    const editEachField = (field) => {
        EditUserInfo(field);
        setSaved("SAVED");
    };

    //fucntion for enable/disbale email field, show/hide of cancel,update buttons
    const updateEmail = (prop) => {
        setEdit(prop);
        setSaveEdit("EDIT_EMAIL");
        if (prop === "CANCEL") {
            setEdit("CANCEL");
            setEmail("");
            setEmailErrMsg("");
        }
    };

    //setting active class for selected gender(highlighting)
    const setClass = (selectedGender) => {
        if (gender === selectedGender || isEdit === "GENDER_CHANGED") {
            return "active";
        }
    };

    //email validation
    const validateMail = () => {
        var error = "";
        if (!email) {
            error = "true";
            setEmailErrMsg("This field is required");
            setMailError(true);
        } else if (email) {
            if (
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
                !email.includes(".com")
            ) {
                error = "true";
                setEmailErrMsg("Enter a valid email");
                setMailError(true);
            }
        } else {
            error = "";
            setEmailErrMsg("");
            setMailError(false);
        }
        return error;
    };

    // email verification api
    const verifyEmail = async () => {
        setMailLoader(true);
        const mailError = validateMail();
        mailError !== "true" &&
            (await dettConfig
                .post(
                    "api/v1/accounts/email/verify/",
                    { email: email },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setMailLoader(false);
                        setViewOtpModal(true);

                        //closing save button after editing
                        setSaveEdit("SAVE_EMAIL");
                        dispatch({
                            type: "UPDATE_ACCOUNT_DETAILS",
                            account_details: {
                                email: email,
                            },
                        });
                    }
                })
                .catch((error) => {
                    setMailLoader(false);
                }));
        setMailLoader(false);
    };

    return (
        <>
            {
                <VerifyOtpModal
                    viewOtpModal={viewOtpModal}
                    setViewOtpModal={setViewOtpModal}
                    setEmail={setEmail}
                    setEdit={setEdit}
                    setSaveEdit={setSaveEdit}
                    mailError={mailError}
                />
            }

            <MainContainer
                className="main-container"
                isEdit={isEdit}
            >
                <TopContainer>
                    <TitleBox>
                        <LeftArrowBox to={"/account"}>
                            <img
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                                }
                                alt="left-arrow"
                            />
                        </LeftArrowBox>
                        <PageTitle>Profile & Security</PageTitle>
                    </TitleBox>
                    <ProfilePictureBox>
                        {gender === "male" ? (
                            <img
                                alt="male"
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/male.svg"
                                }
                            />
                        ) : gender === "female" ? (
                            <img
                                alt="male"
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/female.svg"
                                }
                            />
                        ) : (
                            <span> {account_details?.first_letters_of_name} </span>
                        )}
                    </ProfilePictureBox>
                </TopContainer>
                <PersonalContainer className={isEdit === "USER_INFO" && "active"}>
                    <PersonalInformation>
                        <InformationLabel>Personal Information</InformationLabel>

                        <Button
                            className="edit"
                            onClick={() => editAllField("USER_INFO")}
                        >
                            Edit
                        </Button>
                    </PersonalInformation>
                    <UserFullNameContainer>
                        <Label>First Name</Label>

                        <FirstName
                            value={firstName}
                            type="text"
                            readOnly={enableFields("EDIT_FIRSTNAME")}
                            onChange={handleFirstnameChane}
                            placeholder="Firstname"
                            isEdit={isEdit}
                            onClick={() => editEachField("EDIT_FIRSTNAME")}
                        />

                        <Label>Last Name</Label>

                        <LastName
                            value={lastName}
                            type="text"
                            readOnly={enableFields("EDIT_LASTNAME")}
                            onChange={handleLastnameChange}
                            placeholder="Lastname"
                            isEdit={isEdit}
                            onClick={() => editEachField("EDIT_LASTNAME")}
                        />
                        <Error className="firstname-error">{firstNameErr}</Error>
                    </UserFullNameContainer>

                    <GenderContainer className="third-box">
                        <GenderTitle>Gender</GenderTitle>
                        <GenderSelection>
                            <Male>
                                <ButtonContainer
                                    isEdit={isEdit}
                                    className={gender === "male" && "active"}
                                    onClick={() => handleGenderChange("male")}
                                >
                                    {gender === "male" && (
                                        <span className={setClass("male")}></span>
                                    )}
                                </ButtonContainer>

                                <GenderLabel>Male</GenderLabel>
                            </Male>

                            <Female>
                                <ButtonContainer
                                    isEdit={isEdit}
                                    className={gender === "female" && "active"}
                                    onClick={() => handleGenderChange("female")}
                                >
                                    {gender === "female" && (
                                        <span className={setClass("female")}></span>
                                    )}
                                </ButtonContainer>
                                <GenderLabel>Female</GenderLabel>
                            </Female>

                            <Other>
                                <ButtonContainer
                                    isEdit={isEdit}
                                    className={gender === "others" && "active"}
                                    onClick={() => handleGenderChange("others")}
                                >
                                    {gender === "others" && (
                                        <span className={setClass("others")}></span>
                                    )}
                                </ButtonContainer>
                                <GenderLabel>Other</GenderLabel>
                            </Other>
                        </GenderSelection>
                    </GenderContainer>

                    <BirthdayContainer
                        saveBtnloader={saveBtnloader}
                        isEdit={isEdit}
                        dobErrMsg={dobErrMsg}
                        className={`fourth-box ${
                            screenWidth >= 768 && (isEdit === "USER_INFO" || dobErrMsg !== "")
                                ? "active"
                                : saveBtnloader && "active"
                        }`}
                    >
                        <BirthDay>
                            <BirthdayLabel>Birthday</BirthdayLabel>
                        </BirthDay>

                        <Birthday
                            typeof="text"
                            value={dob}
                            readOnly={enableFields("EDIT_DOB")}
                            onChange={handleDobChange}
                            placeholder="Birthday"
                            maxLength={10}
                            isEdit={isEdit}
                            onClick={() => editEachField("EDIT_DOB")}
                        />
                        {<Error className="dob-errormessage">{dobErrMsg}</Error>}

                        <SaveButton
                            className="save"
                            onClick={() => {
                                updateProfile("USER_INFO");
                            }}
                        >
                            {saveBtnloader ? <ButtonLoader /> : "Save"}
                        </SaveButton>
                    </BirthdayContainer>
                </PersonalContainer>

                <EmailContainer className={isEdit === "EMAIL" && "active"}>
                    <Email>
                        <EmailLabel>Email Address</EmailLabel>
                        {saveEdit === "SAVE_EMAIL" && !email_verified ? (
                            <Button onClick={() => updateEmail("EMAIL")}>Update</Button>
                        ) : saveEdit === "EDIT_EMAIL" && isEdit !== "CANCEL" ? (
                            <Button onClick={() => updateEmail("CANCEL")}>Cancel</Button>
                        ) : (
                            !email_verified && (
                                <Button
                                    className="save-email"
                                    onClick={() => updateEmail("EMAIL")}
                                >
                                    Update
                                </Button>
                            )
                        )}
                    </Email>
                    <EmailInput
                        type="text"
                        placeholder="Email"
                        readOnly={isEdit !== "EMAIL"}
                        value={email}
                        onChange={handleEmailChange}
                        isEdit={isEdit}
                        saveEdit={saveEdit}
                    />
                    <ContinueButton
                        className="continue"
                        onClick={() => {
                            verifyEmail();
                        }}
                    >
                        {mailLoader ? <ButtonLoader /> : "Continue"}
                    </ContinueButton>
                    <Error className="email-error">{emailErrMsg}</Error>
                </EmailContainer>

                <PhoneNumberContainer>
                    <NumberDetails>
                        <Number>Number</Number>
                    </NumberDetails>
                    <PhoneNumber
                        type="number"
                        placeholder={userPhoneNumber}
                        readOnly
                        value={userPhoneNumber}
                    />
                </PhoneNumberContainer>
                {saved !== "" &&
                    (isEdit === "EDIT_DOB" ||
                        isEdit === "EDIT_FIRSTNAME" ||
                        isEdit === "EDIT_LASTNAME" ||
                        gender !== defaultGender) && (
                        <OverLay>
                            <SaveButtonBox
                                onClick={() => updateProfile(null, "CLOSE_SAVE_BUTTON")}
                                className="save-button-box"
                            >
                                {btnLoader ? (
                                    <ButtonLoader />
                                ) : (
                                    <SaveButton className="save-button">Save</SaveButton>
                                )}
                            </SaveButtonBox>
                        </OverLay>
                    )}
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        background: #fff;
        padding: 0;
        transition: all 0.5s ease-in-out;
        //increasing height when editing input(when save button appears)
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL" ? "0" : "50px"};
    }

    @media all and (max-width: 360px) {
        padding: 10px;
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL" ? "0" : "50px"};
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
        padding: 20px;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;
const ProfilePictureBox = styled.div`
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    // span = First letters of name
    span {
        font-size: 35px;
        font-family: "dm_sansboldmedium";
        display: inline-block;
        text-transform: uppercase;
        background: #ffffff;
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #ff5e48;
        color: #ff5e48;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const PersonalContainer = styled.div`
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    &.active {
        input {
            outline: 1.5px solid #ff5e48;
        }
    }
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding-bottom: 10px;
        //classnames are for changing order of div in responsive
        div.third-box {
            order: 4;
        }
        div.fourth-box {
            order: 3;
            margin-bottom: 20px;
        }
    }
`;

const PersonalInformation = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const InformationLabel = styled.h1`
    font-size: 18px;
    font-family: "dm_sansboldmedium";
    margin-right: 10px;

    @media all and (max-width: 768px) {
        display: none;
    }
`;

const SaveButtonBox = styled.div`
    display: none;
    padding: 0 20px 20px 20px;
    position: fixed;
    z-index: 25;
    bottom: 10px;
    height: 42px;
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    @media all and (max-width: 480px) {
        width: 90%;
    }

    div.save-button {
        width: 100%;
        color: #fff;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media all and (max-width: 768px) {
        display: block;
    }
`;

const Button = styled.h2`
    font-size: 16px;
    color: #ff5e48;
    font-family: "dm_sansregular";
    cursor: pointer;

    @media all and (max-width: 768px) {
        //hide edit button in the top
        &.edit {
            display: none;
        }
    }
`;

const UserFullNameContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;

const Label = styled.label`
    display: inline-block;
    width: 100%;
    display: none;
    font-family: "dm_sansboldmedium";
    font-size: 18px;
    color: #0a0a0a;
    margin-bottom: 10px;
    @media all and (max-width: 768px) {
        display: block;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const FirstName = styled.input`
    margin-right: 10px;
    //toggling input color when edit button is clicked(when field enabled)
    color: ${({ isEdit }) =>
        isEdit === "USER_INFO" || isEdit === "EDIT_FIRSTNAME" ? "#000" : "#777a86"};
    font-size: 14px;
    font-family: "dm_sansregular";
    border: 1px solid #d4d4d4;
    width: 60%;
    padding: 12px;
    border-radius: 5px;
    min-height: 40px !important;
    //toggling cursor style when edit button is clicked(when field enabled)
    cursor: ${({ isEdit }) => (isEdit === "USER_INFO" ? "text" : "default")};
    //adding highlight to input which donot have readonly attribut(input that is editable)
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }

    @media all and (max-width: 980px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
`;

const LastName = styled.input`
    //toggling input color when edit button is clicked(when field enabled)
    color: ${({ isEdit }) =>
        isEdit === "USER_INFO" || isEdit === "EDIT_LASTNAME" ? "#000" : "#777a86"};
    font-size: 14px;
    font-family: "dm_sansregular";
    border: 1px solid #d4d4d4;
    width: 60%;
    padding: 12px;
    border-radius: 5px;
    min-height: 40px !important;
    //toggling cursor style when edit button is clicked(when field enabled)
    cursor: ${({ isEdit }) => (isEdit === "USER_INFO" ? "text" : "default")};
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const GenderContainer = styled.div`
    margin-bottom: 20px;
`;

const GenderTitle = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 10px;
    color: #0a0a0a;

    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;

const GenderSelection = styled.div`
    display: flex;
    align-items: center;
`;

const Male = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

const ButtonContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid;
    border-color: #89939e;
    //toggling border color when edit button is clicked
    &.active {
        border-color: ${({ isEdit }) =>
            isEdit === "USER_INFO" || isEdit === "GENDER_CHANGED" ? "#ff5e48" : "#89939e"};
    }
    span {
        width: 10px;
        display: inline-block;
        height: 10px;
        border-radius: 50%;
        overflow: hidden;
        //adding orage color(highlight) when gender is selected
        &.active {
            background-color: ${({ isEdit }) =>
                isEdit === "USER_INFO" || isEdit === "GENDER_CHANGED" ? "#ff5e48" : "#89939e"};
        }
    }
`;

const GenderLabel = styled.h2`
    font-size: 14px;
    font-family: "dm_sansregular";
    color: #777a86;
`;

const Female = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

const Other = styled.div`
    display: flex;
    align-items: center;
    h2.save {
        margin-left: 10px;
    }
`;

const BirthdayContainer = styled.div`
    transition: all 0.5s ease-in-out;
    //increasing height(for showing save button) when edit button clicked
    height: ${({ saveBtnLoader, isEdit }) =>
        saveBtnLoader && isEdit === "USER_INFO" ? "135px" : "80px"};
    overflow-y: ${({ saveBtnLoader, isEdit }) =>
        saveBtnLoader && isEdit === "USER_INFO" ? "visible" : "clip"};

    //save button of DOB, F-NAME, L-NAME, GENDER
    div.save {
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.active {
        //to prevent closing of save button when loader is active
        height: ${({ saveBtnLoader }) =>
            saveBtnLoader === true ? "135px" : saveBtnLoader === false ? "80px" : "135px"};
        overflow: visible;
        transition: all 0.5s ease-in-out;
        div.save {
            transition: all 0.5s ease-in-out;
        }
    }
`;

const BirthDay = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const BirthdayLabel = styled.h2`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-right: 10px;
    color: #0a0a0a;

    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;

const Birthday = styled.input`
    //toggling font color when edit button clicked
    color: ${({ isEdit }) =>
        isEdit === "USER_INFO" || isEdit === "EDIT_DOB" ? "#000" : "#777a86"};
    width: 100%;
    padding: 12px;
    border: 1px solid #777a864a;
    font-size: 14px;
    font-family: "dm_sansregular";
    border-radius: 5px;
    //toggling cursor style when edit button clicked
    cursor: ${({ isEdit }) => (isEdit === "USER_INFO" ? "text" : "default")};
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }
`;

const EmailContainer = styled.div`
    position: relative;
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    height: 120px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    span.continue {
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    &.active {
        //increasing height of email section for showing save button
        height: 175px;
        overflow: visible;
        transition: all 0.5s ease-in-out;
        //adding transition when save button appears
        span.continue {
            opacity: 1;
            transition: all 0.5s ease-in-out;
        }
        //changing outline color(border) when editing
        input {
            outline: 1.5px solid #ff5e48;
        }
    }

    @media all and (max-width: 768px) {
        margin-bottom: 0;
        padding-top: 0;
        height: 80px;
        &.active {
            height: 140px;
        }
    }
`;

const Email = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const EmailLabel = styled.h2`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-right: 10px;
    color: #0a0a0a;

    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;

const EmailInput = styled.input`
    border: 1px solid #777a8659;
    border-radius: 5px;
    padding: 12px;
    //toggling font color when edit button clicked
    color: ${({ isEdit }) => (isEdit === "EMAIL" ? "#000" : "#777a86")};
    width: 100%;
    font-family: "dm_sansregular";
    font-size: 14px;
    //toggling cursor style when edit button clicked
    cursor: ${({ isEdit }) => (isEdit !== "EMAIL" ? "default" : "text")};
    cursor: ${({ saveEdit }) => (saveEdit !== "EDIT_EMAIL" ? "default" : "text")};
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }
`;

const ContinueButton = styled.span`
    width: fit-content;
    color: #fff;
    font-size: 16px;
    font-family: "dm_sansregular";
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    border-radius: 8px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 30px;
    margin-top: 20px;
    height: 42px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 127px;
`;

const OverLay = styled.div`
    width: 100%;
    height: 65px;
    background-color: #fff;
    bottom: 0;
    z-index: 100;
    position: fixed;
    @media all and (max-width: 480px) {
        width: 100%;
        left: 0;
        box-shadow: 0px -3px 13px rgba(156, 156, 156, 0.15);
    }
`;

const SaveButton = styled.div`
    &.save {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        color: #fff;
        font-size: 16px;
        font-family: "dm_sansregular";
        background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
        border-radius: 8px;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 30px;
        margin-top: 20px;
        height: 42px;
    }
`;

const PhoneNumberContainer = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 10px;
`;

const NumberDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const Number = styled.h3`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-right: 10px;

    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;

const PhoneNumber = styled.input`
    border: 1px solid #777a8659;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    font-family: "dm_sansregular";
    font-size: 14px;
    color: #777a86;
    cursor: default;
    :not([readonly]):focus {
        outline: 1.5px solid #ff5e48;
    }
`;

const Error = styled.p`
    font-size: 14px;
    color: red;
    font-family: "dm_sansregular";
    position: absolute;
    &.firstname-error {
        bottom: -22px;
        @media all and (max-width: 768px) {
            bottom: 70px;
            left: 0;
        }
    }
    &.email-error {
        left: 20px;
        bottom: -22px;
    }
`;
