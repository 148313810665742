import React from "react";

//packages
import styled from "styled-components";

//components
import Header from "../../includes/header/DettLandingPageHeader";
import HomeFooterpage from "../home/HomeFooterpage";

function EmptyCart() {
    return (
        <>
            <Header />
            <MainContainer>
                <Wrapper className="wrapper">
                    <ContentBox>
                        <EmptyCartImages>
                            <img
                                src={
                                    require("../../../assets/images/empty-pages/cart-empty.svg")
                                        .default
                                }
                                alt="empty-cart"
                            />
                        </EmptyCartImages>
                        <Title>It feels so light !</Title>
                        <Paragraph>There is nothing in your cart, lets add some items </Paragraph>
                        <Button>Add Items From Wishlist</Button>
                    </ContentBox>
                </Wrapper>
            </MainContainer>
            <HomeFooterpage />
        </>
    );
}

export default EmptyCart;

const MainContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;
`;
const Wrapper = styled.section``;
const ContentBox = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const EmptyCartImages = styled.div`
    width: 150px;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        width: 100px;
    }
`;
const Title = styled.h2`
    font-size: 24px;
    color: #282c3f;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin-bottom: 30px;
    color: #777a86;
    font-family: "dm_sansregular";
    @media all and (max-width: 360px) {
        width: 80%;
        text-align: center;
    }
`;
const Button = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    color: #ff5e48;
    border: 1px solid #ff5e48;
    font-family: "dm_sansboldmedium";
`;
