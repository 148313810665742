import React, { useState } from "react";

//packages
import styled from "styled-components";

//images
const Rosegift =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/gift-rose-icon.svg";
const gift =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/gift.svg";

export default function ReusableButton({ title, styleProp, onClickFunction, color, width }) {
    //states
    const [buttonImage, setButtonImage] = useState("");
    const [hovered, setHovered] = useState(false);

    return (
        <ButtonDiv
            id="reusable-button"
            onMouseEnter={() => setButtonImage(true)}
            onClick={onClickFunction}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={hovered ? "animate" : ""}
            color={color}
            width={width}
        >
            <Text
                color={color}
                styleProp={styleProp}
            >
                {title}
            </Text>
            <ImageDiv type={buttonImage}>
                <GiftImg
                    src={color === "white" ? Rosegift : gift}
                    alt="gift"
                />
            </ImageDiv>
        </ButtonDiv>
    );
}

const ButtonDiv = styled.div`
    height: 45px;
    width: ${({ width }) =>
        width === "max"
            ? "150px"
            : width === "min"
            ? "100px"
            : width === "medium"
            ? "140px"
            : "130px"};
    border-radius: 5px;
    background: ${({ color }) =>
        color === "white"
            ? "#fff"
            : color === "pink"
            ? "#F36097"
            : "linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%)"};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease !important;

    &.animate {
        div {
            opacity: 1;
            transition: all 0.4s ease;
        }
        h1 {
            transform: translateX(-15px);
            transition: all 0.4s ease;
        }
    }

    @media all and (max-width: 640px) {
        /* width: ${({ width }) => (width === "max" ? "130px" : "110px")}; */
        height: 42px;
    }
    @media all and (max-width: 480px) {
        /* width: ${({ width }) => (width === "max" ? "120px" : "100px")}; */
    }
`;

const Text = styled.h1`
    color: ${({ color }) => (color === "white" ? "#F36097" : "#fff")};
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    transition: all 0.4s ease;
    user-select: none;
`;

const ImageDiv = styled.div`
    width: 18px;
    opacity: 0;
    transition: all 0.4s ease;
    position: absolute;
    right: 12px;
`;

const GiftImg = styled.img`
    width: 100%;
    display: block;
`;
