import React, { useState } from "react";

//packages
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function HomeFooterpage() {
    //states
    const [image, setImage] = useState(false);

    //scroll to top function
    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <Border />
            <MainContainer>
                <SubContainer className="wrapper">
                    <Container>
                        <ListBox>
                            <LogoBox>
                                <DettLogoBox
                                    to=""
                                    onClick={(e) => {
                                        scrollToTop(e);
                                    }}
                                >
                                    <Logo
                                        src={
                                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/dett-logo.svg"
                                        }
                                        alt="dett-logo"
                                    />
                                </DettLogoBox>
                                <TalropLogoBox
                                    href="https://talrop.com/"
                                    target="_blank"
                                >
                                    <Logo
                                        src={
                                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/talrop-logo.svg"
                                        }
                                        alt="talrop-logo"
                                    />
                                </TalropLogoBox>
                            </LogoBox>
                            <ListItem className="last">
                                <TitleBox>
                                    <Title>About</Title>
                                </TitleBox>
                                <NavigationButton to={"/about"}>About Us</NavigationButton>
                                <NavigationButton to="/#formpage">Contact Us</NavigationButton>
                                <NavigationButton to="/careers">Careers</NavigationButton>
                            </ListItem>

                            <ListItem className="third-box">
                                <TitleBox>
                                    <Title>Policy</Title>
                                </TitleBox>

                                <NavigationButton
                                    to="/termsandcondition"
                                    target="_blank"
                                >
                                    Terms of Use
                                </NavigationButton>

                                <NavigationButton
                                    to="/privacypolicy"
                                    target="_blank"
                                >
                                    Privacy
                                </NavigationButton>
                            </ListItem>
                            <LastBox>
                                <HeadOffice>Head Office </HeadOffice>
                                <Paragraph>
                                    Dett Private Limited, 3rd floor, 41/418Z, Cabin no 2, Oberon
                                    Mall, NH Ln, Padivattom, Edappally, Ernakulam, Kerala 682024
                                </Paragraph>

                                <BottomBox>
                                    <SocialIconBox
                                        onMouseEnter={() => setImage("face")}
                                        onMouseLeave={() => setImage("")}
                                        href="https://www.facebook.com/dettworld?mibextid=ZbWKwL"
                                        target="_blank"
                                    >
                                        {image === "face" ? (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Facebook.svg"
                                                }
                                                alt="social-icon"
                                            />
                                        ) : (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Facebookash.svg"
                                                }
                                                alt="social-icon"
                                            />
                                        )}
                                    </SocialIconBox>
                                    <SocialIconBox
                                        onMouseEnter={() => setImage("insta")}
                                        onMouseLeave={() => setImage("")}
                                        href="https://instagram.com/dett_world?igshid=ZDdkNTZiNTM="
                                        target="_blank"
                                    >
                                        {image === "insta" ? (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/instagram-colored.svg"
                                                }
                                                alt="instagram"
                                            />
                                        ) : (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Instagram.svg"
                                                }
                                                alt="instagram"
                                            />
                                        )}
                                    </SocialIconBox>
                                    <SocialIconBox
                                        onMouseEnter={() => setImage("linkedin")}
                                        onMouseLeave={() => setImage("")}
                                        href="https://www.linkedin.com/company/dettworld/"
                                        target="_blank"
                                    >
                                        {image === "linkedin" ? (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Linkedin.svg"
                                                }
                                                alt="linkedin"
                                            />
                                        ) : (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Linkedinash.svg"
                                                }
                                                alt="linkedin"
                                            />
                                        )}
                                    </SocialIconBox>
                                    <SocialIconBox
                                        onMouseEnter={() => setImage("youtube")}
                                        onMouseLeave={() => setImage("")}
                                        href="https://youtube.com/@dettmedia"
                                        target="_blank"
                                    >
                                        {image === "youtube" ? (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/youtube-coloured.svg"
                                                }
                                                alt="youtube"
                                            />
                                        ) : (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/youtube-grey.svg"
                                                }
                                                alt="youtube"
                                            />
                                        )}
                                    </SocialIconBox>
                                    <SocialIconBox
                                        onMouseEnter={() => setImage("twit")}
                                        onMouseLeave={() => setImage("")}
                                        href="https://twitter.com/dettworld?t=d47SSDlwb7dIHOAhqXx0Dg&s=09"
                                        target="_blank"
                                    >
                                        {image === "twit" ? (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Twitter.svg"
                                                }
                                                alt="social-icon"
                                            />
                                        ) : (
                                            <SocialIcon
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Twitterash.svg"
                                                }
                                                alt="social-icon"
                                            />
                                        )}
                                    </SocialIconBox>
                                </BottomBox>
                            </LastBox>
                        </ListBox>
                    </Container>
                    <BottomContainer>
                        <Border />
                        <AppContainerCopy>
                            <ImgContainerCopy
                                target="_blank"
                                href="https://www.facebook.com/dettworld?mibextid=ZbWKwL"
                            >
                                <AppCopy
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Facebook.svg"
                                    }
                                    alt="fb"
                                />
                            </ImgContainerCopy>

                            <ImgContainerCopy
                                target="_blank"
                                href="https://instagram.com/dett_world?igshid=ZDdkNTZiNTM="
                            >
                                <AppCopy
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/instagram-colored.svg"
                                    }
                                    alt="instagram"
                                />
                            </ImgContainerCopy>

                            <ImgContainerCopy
                                target="_blank"
                                href="https://www.linkedin.com/company/dettworld/"
                            >
                                <AppCopy
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Linkedin.svg"
                                    }
                                    alt="linkedin"
                                />
                            </ImgContainerCopy>

                            <ImgContainerCopy
                                target="_blank"
                                href="https://youtube.com/@dettmedia"
                            >
                                <AppCopy
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/youtube-coloured.svg"
                                    }
                                    alt="youtube"
                                />
                            </ImgContainerCopy>

                            <ImgContainerCopy
                                target="_blank"
                                href="https://twitter.com/dettworld?t=d47SSDlwb7dIHOAhqXx0Dg&s=09"
                            >
                                <AppCopy
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Twitter.svg"
                                    }
                                    alt="twitter"
                                />
                            </ImgContainerCopy>
                        </AppContainerCopy>
                        <CopyRight>
                            @2023 Dett Pvt Ltd, Inc <br />
                            <FooterLink
                                href="/termsandcondition"
                                target="_blank"
                            >
                                Terms of Services{" "}
                            </FooterLink>
                            |{" "}
                            <FooterLink
                                href="/privacypolicy"
                                target="_blank"
                            >
                                Privacy Policy
                            </FooterLink>
                        </CopyRight>
                    </BottomContainer>
                </SubContainer>
            </MainContainer>
        </>
    );
}

const Border = styled.hr`
    background-color: #f3f3f3;
    height: 1px;
    width: 100%;
`;
const MainContainer = styled.footer`
    padding: 50px 0px 30px;

    @media all and (max-width: 768px) {
        padding: 50px 0px 30px;
    }

    @media all and (max-width: 640px) {
        padding: 30px 0px 25px 0px;
    }

    @media all and (max-width: 480px) {
        padding: 30px 0px 30px 0px;
    }

    @media all and (max-width: 360px) {
        padding-bottom: 10px;
    }
`;

const SubContainer = styled.div`
    &.wrapper {
        width: 75%;
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: auto;

    @media all and (max-width: 1100px) {
        align-items: unset;
    }

    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }

    @media all and (max-width: 480px) {
        bottom: -5px;
    }
`;

const LogoBox = styled.div`
    border: 1px solid #ff5e48;
    border-radius: 14px;
    max-width: 250px;
    height: auto;
    padding: 30px;
    display: flex;
    justify-content: center;
    margin-right: 75px;
    @media all and (max-width: 1100px) {
        margin-right: 0;
    }

    @media all and (max-width: 768px) {
        width: 45%;
    }

    @media all and (max-width: 540px) {
        width: 100%;
        max-width: none;
        justify-content: space-around;
        margin-bottom: 40px;
    }
`;

const DettLogoBox = styled(Link)`
    width: 90px;
    display: inline-block;

    @media all and (max-width: 1280px) {
        width: 80px;
    }

    @media all and (max-width: 1080px) {
        width: 70px;
    }
`;

const TalropLogoBox = styled.a`
    width: 90px;
    display: inline-block;
    margin-left: 15px;
    @media all and (max-width: 1280px) {
        width: 80px;
    }
`;

const Logo = styled.img`
    width: 100%;
    display: block;
`;

const AppContainerCopy = styled.div`
    width: 81%;
    margin: 0 auto;
    display: none;

    @media all and (max-width: 640px) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 30px 0px 30px 0px;
        align-items: center;
    }

    @media all and (max-width: 480px) {
        margin: 25px 0px 20px 0px;
    }

    @media all and (max-width: 360px) {
        margin: 20px 0px;
    }
`;

const ImgContainerCopy = styled.a`
    width: 30px;
    transition: 0.4s ease;
    cursor: pointer;
    margin-right: 20px;
`;

const AppCopy = styled.img`
    transition: 0.4s ease;
    display: block;
    width: 100%;
`;

const ListBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    @media all and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }

    @media all and (max-width: 980px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        justify-content: space-between;
    }

    @media all and (max-width: 640px) {
        height: auto;
        flex-wrap: wrap;
    }
`;

const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
        width: 20%;
        @media all and (max-width: 540px) {
            width: 30%;
            align-items: center;
        }
    }

    &.third-box {
        width: 20%;

        @media all and (max-width: 768px) {
            width: 20%;
        }
        @media all and (max-width: 540px) {
            width: 30%;
            align-items: center;
        }

        @media all and (max-width: 360px) {
            width: 35%;
        }
    }

    &:last-child h2 {
        margin-bottom: 6px;
    }
`;

const TitleBox = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
`;

const Title = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;

const NavigationButton = styled(HashLink)`
    display: inline-block;
    font-size: 14px;
    color: #7e818c;
    margin-bottom: 15px;
    font-family: "dm_sansregular";
    display: inline-block;
    cursor: pointer;
    :hover {
        color: #ff5e48;
    }

    @media all and (max-width: 980px) {
        font-size: 13px;
    }

    @media all and (max-width: 640px) {
        margin-bottom: 5px;
    }

    @media all and (max-width: 360px) {
        margin-bottom: 8px;
    }
`;

const BottomContainer = styled.div`
    text-align: center;
    margin-top: 35px;

    @media all and (max-width: 980px) {
        margin-top: 20px;
    }

    @media all and (max-width: 360px) {
        margin-top: 0px;
    }
`;

const CopyRight = styled.p`
    font-size: 14px;
    color: #a0a2aa;
    padding-top: 15px;

    br {
        display: none;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }

    @media all and (max-width: 360px) {
        margin-bottom: 20px;
        br {
            display: block;
        }
    }
`;
const FooterLink = styled.a`
    font-size: 14px;
    color: #a0a2aa;

    :hover {
        color: #ff5e48;
    }
`;

const LastBox = styled.div`
    width: 32%;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1200px) {
        width: 40%;
    }
    @media all and (max-width: 1100px) {
        width: 75%;
        margin-top: 50px;

        justify-content: center;
        align-items: center;
    }

    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 540px) {
        margin-top: 40px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const HeadOffice = styled.span`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    display: inline-block;
    margin-bottom: 15px;
`;
const Paragraph = styled.p`
    font-size: 14px;
    color: #7e818c;
    margin-bottom: 15px;
    font-family: "dm_sansregular";
    display: inline-block;
    @media all and (max-width: 1100px) {
        text-align: center;
        width: 75%;
    }

    @media all and (max-width: 980px) {
        font-size: 13px;
    }

    @media all and (max-width: 680px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 0;
    }

    @media all and (max-width: 540px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;

const SocialIconBox = styled.a`
    width: 30px;
    margin-right: 20px;
    transition: all 0.2s ease-in-out;
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 980px) {
        width: 25px;
    }
`;
const SocialIcon = styled.img`
    width: 100%;
    display: block;
    transition: all 0.2s ease-in-out;
`;
const BottomBox = styled.div`
    display: flex;
    margin-top: 20px;
    @media all and (max-width: 1080px) {
        margin-bottom: 25px;
    }
    @media all and (max-width: 980px) {
        margin-top: 0;
    }
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }

    @media all and (max-width: 640px) {
        display: none;
    }
`;
