import React from "react";

//packages
import styled from "styled-components";

function EmptyAddress() {
    return (
        <MainContainer>
            <Wrapper className="wrapper">
                <ContentBox>
                    <EmptyAddressImage>
                        <img
                            src={
                                require("../../../assets/images/empty-pages/empty-address.svg")
                                    .default
                            }
                            alt="empty-cart"
                        />
                    </EmptyAddressImage>
                    <Title>Save Your Address Now</Title>
                    <Paragraph>
                        Add your home and office addresses and enjoy faster checkout
                    </Paragraph>
                    <Button>+ Add New Address</Button>
                </ContentBox>
            </Wrapper>
        </MainContainer>
    );
}

export default EmptyAddress;

const MainContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;
    background: #f8f8f8;
    border-radius: 5px;
    overflow: hidden;
`;
const Wrapper = styled.section``;
const ContentBox = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const EmptyAddressImage = styled.div`
    width: 250px;
    @media all and (max-width: 480px) {
        width: 200px;
    }
`;
const Title = styled.h2`
    font-size: 20px;
    color: #282c3f;
    text-align: center;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin-bottom: 30px;
    color: #777a86;
    text-align: center;
    font-family: "dm_sansregular";
    @media all and (max-width: 360px) {
        width: 95%;
        text-align: center;
    }
`;

const Button = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    color: #ff5e48;
    border: 1px solid #ff5e48;
    font-family: "dm_sansboldmedium";
`;
