import React, { useEffect, useState, useContext } from "react";

//css
import "../src/assets/css/Style.css";

//packages
import { BrowserRouter as Router } from "react-router-dom";

//components
import MainRouter from "./components/routing/router/MainRouter";
import SessionExpireModal from "./components/includes/modal/SessionExpireModal";

//contest
import { Context } from "./components/Contexts/Store";

//axios
import { dettConfig } from "./axiosConfig";

function App() {
    //sessionModal state
    const [sessionModal, setSessionModal] = useState(false);

    //current location
    const location = window.location.pathname;

    const {
        state: { user_data },
    } = useContext(Context);

    //authentication api[checking if access token is valid or not]
    useEffect(() => {
        if (user_data?.access_token && location !== "/login") {
            dettConfig
                .get("api/v1/web/app/", {
                    headers: {
                        Authorization: `Bearer ${user_data?.access_token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 401) {
                        setSessionModal(true);
                    }
                })
                .catch((error) => {
                    setSessionModal(true);
                });
        }
    }, []);

    return (
        <>
            <Router>
                <SessionExpireModal
                    sessionModal={sessionModal}
                    setSessionModal={setSessionModal}
                />
                <MainRouter />
            </Router>
        </>
    );
}

export default App;
