import React from "react";

//packages
import styled from "styled-components";
import { Fade } from "react-reveal";

function Teams() {
    const datas = [
        {
            id: 1,
            Name: "Ajeesh S",
            Postion: "Co-Founder & CEO",
            Iconone:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Facebook.svg",
            Icontwo:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Twitter.svg",
            Iconthird:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Linkedin.svg",
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Ajeesh.png",
            Link: "https://www.linkedin.com/in/ajeesh-satheesan",
        },
        {
            id: 2,
            Name: "Jishnu Prakash",
            Postion: "Co-Founder",
            Iconone:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Facebook.svg",
            Icontwo:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Twitter.svg",
            Iconthird:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Linkedin.svg",
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/jishnu.png",
            Link: "https://www.linkedin.com/in/jishnujp",
        },
    ];

    return (
        <div>
            <MainContainer className="wrapper">
                <Fade
                    bottom
                    cascade
                >
                    <TeamTitle>Behind the Team</TeamTitle>
                    <TeamDescribtion>
                        We are constantly evolving & improving to be one of the top gifting service
                        providers. Our mission is to achieve more by working hard with focus &
                        dedication.
                    </TeamDescribtion>

                    <TeamContainer>
                        {datas.map((item) => (
                            <OnePerson
                                key={item.id}
                                className="team"
                            >
                                <ImageContainer>
                                    <Image
                                        src={item.image}
                                        alt="img"
                                    />
                                </ImageContainer>
                                <Backgroundhover className="hover-item">
                                    <Name>{item.Name}</Name>
                                    <Postion>{item.Postion}</Postion>
                                    <MediaDiv className="icon">
                                        <IconDiv
                                            target="_blank"
                                            href={item.Link}
                                        >
                                            <Icon
                                                src={item.Iconthird}
                                                alt="third"
                                            />
                                        </IconDiv>
                                    </MediaDiv>
                                </Backgroundhover>
                            </OnePerson>
                        ))}
                    </TeamContainer>
                </Fade>
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div`
    padding: 150px 0 95px 0;

    @media all and (max-width: 1280px) {
        padding: 150px 0 120px 0;
    }

    @media all and (max-width: 1080px) {
        padding: 150px 0 85px 0;
    }
    @media all and (max-width: 980px) {
        // temp change
        padding: 70px 0 70px 0;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 60px 0;
    }
`;
const TeamTitle = styled.h1`
    color: #282c3f;
    font-size: 38px;
    font-family: "dm_sansboldbold";
    text-align: center;
    margin-bottom: 20px;
    line-height: 20px;
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
    }
`;
const TeamDescribtion = styled.p`
    color: #777a86;
    font-size: 15px;
    line-height: 20px;
    width: 55%;
    text-align: center;
    font-family: "dm_sansboldmedium";
    margin: 0 auto;
    margin-bottom: 50px;
    line-height: 20px;
    @media all and (max-width: 768px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 14px;
    }
`;

const Backgroundhover = styled.div`
    position: absolute;
    border-radius: 10px;
    backdrop-filter: blur(111px);
    bottom: 0;
    width: 100%;
    height: 125px;
    opacity: 0;
    z-index: 5;
    transition: all 0.4s ease-in-out;

    @media all and (max-width: 640px) {
        height: 117px;
    }
    @media all and (max-width: 480px) {
        height: 100px;
    }
`;

const ImageContainer = styled.div`
    width: 178px;
    left: 50%;
    transform: translatex(-50%);
    position: absolute;
    bottom: 0px;
    overflow: unset !important;
`;

const OnePerson = styled.div`
    width: 23%;
    height: 265px;
    background: linear-gradient(90deg, #f16d7c -17.28%, #73a0fe 137.28%);
    border-radius: 6px;
    position: relative;
    margin-right: 15px;
    &:hover ${ImageContainer} {
        position: absolute;
        bottom: 6px;
        transition: all 0.2s ease;
    }
    &:hover ${Backgroundhover} {
        display: block;
    }

    @media all and (max-width: 1080px) {
        width: 32%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 547px) {
        width: 47%;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 0;
    }

    &:hover ${ImageContainer} {
        position: absolute;
        bottom: 6px;
        transition: all 0.2s ease;
    }
    &:hover ${Backgroundhover} {
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }

    @media all and (max-width: 1080px) {
        width: 32%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 547px) {
        width: 47%;
    }

    @media all and (max-width: 640px) {
        width: 70%;
        margin-right: 0px;
        margin-bottom: 45px;
        :last-child {
            margin-bottom: 0;
        }
    }

    @media all and (max-width: 480px) {
        width: 85%;
    }
    @media all and (max-width: 360px) {
        width: 90%;
    }
`;

const TeamContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 65px;
    @media all and (max-width: 769px) {
        gap: 15px;
        &::-webkit-scrollbar {
            display: none;
        }
        @media all and (max-width: 641px) {
            justify-content: center;
        }
    } ;
`;

const Image = styled.img`
    width: 100%;
    display: block;
`;

const Name = styled.h3`
    color: #fff;
    text-align: center;
    margin-top: 20px;
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1080px) {
        margin-top: 10px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const Postion = styled.h4`
    color: #fff;
    text-align: center;
    margin-top: 5px;
    font-family: "dm_sansregular";
    font-size: 14px;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const MediaDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
`;
const IconDiv = styled.a`
    width: 35px;
    display: inline-block;
    margin-left: 13px;
    @media all and (max-width: 480px) {
        width: 30px;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;

export default Teams;
