import React from "react";

//packages
import styled from "styled-components";

//components
import Header from "../../../includes/header/DettLandingPageHeader";

//images
const ribbon =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/ribbon-bg.png";

function PartnerLogo({ data }) {
    return (
        <>
            <Header />
            <MainContainer>
                <Wrapper className="wrapper">
                    <ContentBox>
                        <TransparentLogo>
                            <img
                                src={data?.spotlight_logo}
                                alt="steyp-transparent-logo"
                            />
                            <SteypLogo>
                                <img
                                    src={data?.image}
                                    alt="steyp-logo"
                                />
                            </SteypLogo>
                        </TransparentLogo>
                    </ContentBox>
                </Wrapper>
            </MainContainer>
        </>
    );
}

export default PartnerLogo;

const MainContainer = styled.section`
    padding: 180px 0 60px;

    @media all and (max-width: 1280px) {
        padding: 150px 0 50px;
    }

    @media all and (max-width: 980px) {
        padding: 120px 0 40px;
    }

    @media all and (max-width: 768px) {
        padding-bottom: 30px;
    }

    @media all and (max-width: 480px) {
        padding-bottom: 20px;
    }
`;

const Wrapper = styled.section`
    @media all and (max-width: 768px) {
        width: 85%;
    }
`;
const ContentBox = styled.section`
    border: 1px solid #a0a6bc;
    box-shadow: 0px 0px 100px rgba(131, 131, 131, 0.08);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    //
    background: url(${ribbon});
    background-size: 220px;
    background-repeat: no-repeat;
    background-position-x: 104%;
    background-position-y: -45px;
    height: 150px;

    @media all and (max-width: 1080px) {
        background-size: 200px;
        height: 140px;
    }

    @media all and (max-width: 980px) {
        background-position-x: 106%;
        background-position-y: -40px;
    }

    @media all and (max-width: 900px) {
        background-position-y: -35px;
        background-size: 185px;
    }

    @media all and (max-width: 640px) {
        background-position-y: -25px;
        background-size: 155px;
        height: 120px;
    }

    @media all and (max-width: 540px) {
        background-size: 140px;
        background-position-y: -27px;
        height: 110px;
    }

    @media all and (max-width: 400px) {
        background-size: 120px;
        height: 100px;
        background-position-y: -22px;
    }

    @media all and (max-width: 380px) {
        background-size: 115px;
        background-position-y: -16px;
    }

    @media all and (max-width: 350px) {
        background-size: 95px;
        background-position-y: -10px;
    }
`;

const TransparentLogo = styled.div`
    width: 290px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 1080px) {
        width: 250px;
    }
    @media all and (max-width: 640px) {
        width: 230px;
    }

    @media all and (max-width: 560px) {
        width: 200px;
    }

    @media all and (max-width: 480px) {
        width: 170px;
    }
`;

const SteypLogo = styled.div`
    position: absolute;
    /* width: 120px; */
    //new
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media all and (max-width: 1080px) {
        /* width: 100px; */
    }

    @media all and (max-width: 640px) {
        /* width: 90px; */
    }

    @media all and (max-width: 480px) {
        /* width: 80px; */
    }
`;
