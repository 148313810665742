import React from "react";

//packages
import styled from "styled-components";
import { Fade } from "react-reveal";

function CompanyDetails() {
    const datas = [
        {
            id: 1,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/safe-handling.svg",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/pink.svg",
            subtitle: "Safe Handling",
            describtion: "We are obliged to send memorable gifts to your loved ones safely.",
        },
        {
            id: 2,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/careful.svg",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/blue-dot.svg",
            subtitle: " Careful Packaging",
            describtion:
                "We ensure we give the same importance from fragile to high value products.",
        },
        {
            id: 3,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/quality-assurance.svg",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/thrid-icon.svg",
            subtitle: "Quality Assurance",
            describtion: "Utmost attention to quality & every stage of the process of delivery.",
        },
        {
            id: 4,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/careful.svg",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/fourth-icon.svg",
            subtitle: "Quick Delivery",
            describtion:
                "We care for your valuable time and on time delivery of gifts on special occasions.",
        },
        {
            id: 5,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/opengift.png",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/fifth-icon.svg",
            subtitle: "Gesture of Reminding",
            describtion:
                "We are obliged to remind you, your dear one's special occasions. We serve your expectations happily.",
        },
        {
            id: 6,
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/safe-handling.svg",
            smallicon:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/sixth-icon.svg",
            subtitle: "Customized Solutions",
            describtion:
                "To transform your thought process into customized special gifts is our primary responsibility.",
        },
    ];
    return (
        <Div className="wrapper">
            <MainContainer>
                <TitleDiv>
                    <Title>Get the best gifting options here!</Title>
                    <IconDiv>
                        <Icon
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Frame .svg"
                            }
                            alt="frame"
                        />
                    </IconDiv>
                </TitleDiv>
                <TitleDescribation>
                    We help in strengthening relationships to stay in touch and keep that person in
                    your life by sending something special as memories/ gifts.
                </TitleDescribation>
                <TotalDiv>
                    <Fade
                        bottom
                        cascade
                    >
                        <LeftContainer>
                            {datas.map((item) => (
                                <FirstConatiner key={item.id}>
                                    <IconContainer className="icon-box">
                                        <div>
                                            <IconImage
                                                src={item.image}
                                                alt="small-icon"
                                            />
                                        </div>
                                    </IconContainer>

                                    <SubtitleContainer>
                                        <Small>
                                            <img
                                                src={item.smallicon}
                                                alt="small-icon"
                                            />
                                        </Small>
                                        <Subtitle>{item.subtitle} </Subtitle>
                                    </SubtitleContainer>
                                    <Paragraph>{item.describtion} </Paragraph>
                                </FirstConatiner>
                            ))}
                        </LeftContainer>
                    </Fade>

                    <Rightcontainer>
                        <Giftcontainer>
                            <Gift
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/gift-image.png"
                                }
                                alt="gift"
                            />
                        </Giftcontainer>
                        <Giftcontainerresponsive>
                            <Giftres
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/giftresponsive.png"
                                }
                                alt="gift"
                            />
                        </Giftcontainerresponsive>
                    </Rightcontainer>
                </TotalDiv>
            </MainContainer>
        </Div>
    );
}

const Div = styled.div`
    padding: 0 0 150px 0;

    @media all and (max-width: 1280px) {
        /* padding: 150px 0 150px 0; */
    }
    @media all and (max-width: 980px) {
        padding: 60px 0 60px 0;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 60px 0;
    }
    @media all and (max-width: 640px) {
        &.wrapper {
            width: 90%;
        }
    }
`;

const MainContainer = styled.div`
    padding: 80px;
    background: #fcfcfc;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    position: relative;
    @media all and (max-width: 1280px) {
        padding: 70px;
    }
    @media all and (max-width: 768px) {
        padding: 50px;
    }
    @media all and (max-width: 640px) {
        padding: 20px;
    }
    @media all and (max-width: 480px) {
        padding: 30px 20px 0px 20px;
    }
`;
const TitleDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
`;
const Title = styled.h1`
    color: #282c3f;
    font-size: 38px;
    font-family: "dm_sansboldbold";
    margin-right: 8px;
    @media all and (max-width: 980px) {
        font-size: 32px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        text-align: center;
        font-size: 30px;
    }
    @media all and (max-width: 480px) {
        text-align: center;
        font-size: 24px;
    }
`;
const IconDiv = styled.div`
    width: 22px;
    @media all and (max-width: 640px) {
        display: none;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const TitleDescribation = styled.p`
    color: #777a86;
    font-size: 15px;
    line-height: 20px;
    width: 55%;
    text-align: center;
    font-family: "dm_sansboldmedium";
    margin: 0 auto;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
const TotalDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 45px;
`;
const LeftContainer = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @media all and (max-width: 1280px) {
        width: 100%;
        justify-content: center;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 30%;
    }
    @media all and (max-width: 480px) {
        gap: 10px;
    }
`;
const FirstConatiner = styled.div`
    width: 30%;
    @media all and (max-width: 1080px) {
        width: 41%;
    }
    @media all and (max-width: 640px) {
        width: 46%;
    }
    &:nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5) {
        div.icon-box {
            div {
                width: 18px;
                height: 18px;
            }
        }
    }
    @media all and (max-width: 480px) {
        &:nth-child(2),
        :nth-child(3),
        :nth-child(4),
        :nth-child(5) {
            div.icon-box {
                div {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
`;
const IconContainer = styled.div`
    width: 50px;
    background: #fff;
    box-shadow: 0px 4.74492px 16.6072px rgb(0 0 0 / 3%);
    border-radius: 8px;
    margin-bottom: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        width: 16px;
        height: 16px;
    }
    @media all and (max-width: 480px) {
        width: 40px;
        height: 40px;
    }
    div {
        width: 14px;
        height: 14px;
    }
`;
const IconImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    animation: pulse 1.5s infinite;

    @keyframes pulse {
        0% {
            transform: scale(2);
        }
        100% {
            transform: scale(1.4);
        }
    }
    @keyframes pulseinner {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.08);
        }
    }
`;
const SubtitleContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Small = styled.div`
    width: 10px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 7px;
    }
`;
const Subtitle = styled.span`
    color: #282c3f;
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    display: inline-block;
    margin-left: 5px;
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const Paragraph = styled.p`
    color: #777a86;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const Rightcontainer = styled.div`
    width: 30%;

    @media all and (max-width: 1280px) {
        width: 10%;
    }
    @media all and (max-width: 1200px) {
        width: 0;
    }
`;
const Giftcontainer = styled.div`
    width: 342px;
    position: absolute;
    bottom: 0;
    right: 0;
    @media all and (max-width: 1280px) {
        width: 200px;
    }
    @media all and (max-width: 1200px) {
        display: none;
    }
`;
const Gift = styled.img`
    width: 100%;
    display: block;
`;
const Giftcontainerresponsive = styled.div`
    width: 342px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    @media all and (max-width: 640px) {
        width: 200px;
        display: block;
        right: 102px;
    }
    @media all and (max-width: 480px) {
        right: 70px;
    }
    @media all and (max-width: 360px) {
        right: 50px;
    }
`;
const Giftres = styled.img`
    width: 100%;
    display: block;
`;

export default CompanyDetails;
