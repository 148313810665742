import React from "react";

//packages
import styled from "styled-components";

//images
const background =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/person-bg.svg";

function PartnerTestimonials({ testimonials }) {
    return (
        <MainContainer>
            <Wrapper className="wrapper">
                <ContentBox>
                    <TopBox>
                        <Heading>Testimonials</Heading>
                        <Paragraph>
                            Testimonials Value your Employees as individuals with personalized gifts
                            to show appreciation <br /> to your employees.
                        </Paragraph>
                    </TopBox>
                    <CardBox>
                        {testimonials?.map((testimonial) => (
                            <Card key={testimonial?.id}>
                                <RoundBaackground>
                                    <PersonImage>
                                        <img
                                            src={testimonial?.image}
                                            alt="person"
                                        />
                                    </PersonImage>
                                </RoundBaackground>
                                <RightBox>
                                    <Paragraph className="testimonial-paragraph">
                                        {testimonial?.description}
                                    </Paragraph>
                                    <Name className="name">{testimonial?.name}</Name>
                                    <Role className="paragraph">
                                        {testimonial?.company_name}, {testimonial?.designation}
                                    </Role>
                                </RightBox>
                            </Card>
                        ))}
                    </CardBox>
                </ContentBox>
            </Wrapper>
        </MainContainer>
    );
}

export default PartnerTestimonials;

const MainContainer = styled.section`
    padding: 40px 0 50px;
    @media all and (max-width: 1280px) {
        padding: 20px 0 30px;
    }

    @media all and (max-width: 980px) {
        padding: 10px 0 30px;
    }
    @media all and (max-width: 480px) {
        padding: 20px 0;
    }
`;
const Wrapper = styled.section`
    @media all and (max-width: 768px) {
        width: 85%;
    }
`;
const ContentBox = styled.section``;
const TopBox = styled.div`
    margin-bottom: 80px;
    @media all and (max-width: 1280px) {
        margin-bottom: 70px;
    }

    @media all and (max-width: 980px) {
        margin-bottom: 60px;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 50px;
    }
`;
const Heading = styled.h1`
    color: #282c3f;
    margin-bottom: 15px;
    text-align: center;
    font-family: "dm_sansboldbold";
    font-size: 42px;
    @media all and (max-width: 1080px) {
        font-size: 40px;
    }

    @media all and (max-width: 980px) {
        font-size: 38px;
    }

    @media all and (max-width: 768px) {
        font-size: 35px;
    }

    @media all and (max-width: 680px) {
        font-size: 32px;
    }

    @media all and (max-width: 640px) {
        font-size: 30px;
    }

    @media all and (max-width: 480px) {
        font-size: 28px;
    }

    @media all and (max-width: 360px) {
        font-size: 26px;
    }
`;
const Paragraph = styled.p`
    color: #777a86;
    font-family: "dm_sansboldmedium";
    line-height: 1.4em;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    @media all and (max-width: 980px) {
        br {
            display: none;
        }
    }
    @media all and (max-width: 768px) {
        width: 95%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        /* text-align: justify; */
        margin: 0 auto;
    }
    &.testimonial-paragraph {
        font-family: "dm_sansregular";
        margin: 0;
        text-align: left;
        font-size: 15px;
        @media all and (max-width: 900px) {
            /* text-align: center; */
        }
        @media all and (max-width: 640px) {
            font-size: 13px;
        }
        @media all and (max-width: 480px) {
            /* text-align: justify; */
        }
    }
`;

//TESTIMONIAL SECTION STYLES
const CardBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 127px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    @media all and (max-width: 1080px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px;
    box-shadow: 0px 4px 127px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }

    @media all and (max-width: 900px) {
        flex-direction: column;
        padding-bottom: 0;
    }

    @media all and (max-width: 768px) {
        margin: 30px auto 0;
        padding: 0;
        &:first-child {
            margin-top: 0;
        }
    }
`;

const RoundBaackground = styled.div`
    width: 200px;
    height: 200px;
    /* border-radius: 50%; */
    /* background: linear-gradient(210.02deg, rgba(255, 122, 122, 0) 34.43%, #f36097 96.5%); */
    background: url(${background});
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    margin-right: 50px;
    @media all and (max-width: 900px) {
        margin-right: 0;
        margin-bottom: 30px;
    }
    @media all and (max-width: 980px) {
        width: 170px;
        height: 170px;
    }
    @media all and (max-width: 480px) {
        width: 140px;
        height: 140px;
        background: #fff;
    }
    @media all and (max-width: 360px) {
        width: 120px;
        height: 120px;
    }
`;

const PersonImage = styled.div`
    width: 180px;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    overflow: hidden;
    /* bottom: 0; */
    top: 25px;
    left: 20px;
    border: 10px solid #fff;
    background-color: #fff;
    box-shadow: 0px 4px 121px rgba(171, 171, 171, 0.23);
    @media all and (max-width: 980px) {
        width: 150px;
        top: 22px;
    }
    @media all and (max-width: 480px) {
        /* width: 120px; */
        position: unset;
    }
    @media all and (max-width: 360px) {
        /* width: 100px; */
    }
`;

const RightBox = styled.div`
    width: 75%;
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1080px) {
        width: 65%;
    }
    @media all and (max-width: 900px) {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .name {
            order: 1;
        }
    }
    @media all and (max-width: 360px) {
        margin-top: 35px;
    }
`;

const Name = styled.h2`
    color: #282c3f;
    font-family: "dm_sansregular";
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 10px;
    @media all and (max-width: 900px) {
        margin-top: 0;
    }
`;
const Role = styled.h3`
    color: #282c3f;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;
    font-size: 17px;
`;
