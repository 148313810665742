import React, { useState, useContext, useEffect } from "react";

//packages
import styled from "styled-components";

//axios
import { dettConfig } from "../../../axiosConfig";

//contest
import { Context } from "../../Contexts/Store";

//custom hooks
import useOutsideScroll from "../../general/hooks/useOutsideScroll";
import useScreenWidth from "../../general/hooks/useScreenWidth";

//components
import ButtonLoader from "../../general/loader/ButtonLoader";

function VerifyOtpModal({
    viewOtpModal,
    setSaveEdit,
    setViewOtpModal,
    setEdit,
    setEmail,
    mailError,
}) {
    //custom hooks
    useOutsideScroll(viewOtpModal);
    const screenWidth = useScreenWidth();

    //states
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [otpTimer, setOtpTimer] = useState(15);
    const [title, setTitle] = useState("Success!");

    const {
        dispatch,
        state: { user_data, account_details },
    } = useContext(Context);

    const access_token = user_data?.access_token;
    const email = account_details?.email;

    //setting otp
    const handleOtpChange = (e) => {
        setErrorMessage("");
        const value = e.target.value;
        setOtp(value);
    };

    //otp verification api
    const verifyOTP = async () => {
        setLoading(true);
        if (otp === "") {
            setErrorMessage("This field is required");
        }
        otp &&
            (await dettConfig
                .post(
                    "api/v1/accounts/email/verify/confirm/",
                    { email: email, otp: otp },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        dispatch({
                            type: "UPDATE_ACCOUNT_DETAILS",
                            account_details: {
                                email_verified: true,
                            },
                        });
                        setViewOtpModal(false);
                        setEdit(null);
                        setSaveEdit(null);
                        setErrorMessage("");
                    } else {
                        setErrorMessage("Enter a valid OTP");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                }));
        setLoading(false);
    };

    // otp timer function
    useEffect(() => {
        if (otpTimer > 0) {
            setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
        }
    }, [otpTimer]);

    // formatting time into minutes and seconds
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
        return `${minutes}:${formattedSeconds}`;
    }

    //resend otp api
    const resendOtp = () => {
        dettConfig
            .post(
                "api/v1/accounts/email/verify/resend/",
                { email: email },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setOtpTimer(15);
                } else {
                    if (data.title === "Failed!") {
                        setTitle(data.title);
                        setOtpTimer(600);
                    } else {
                        setOtpTimer(15);
                        setTitle("");
                    }
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {});
    };

    return (
        <div>
            {viewOtpModal && !mailError && (
                <Overlay
                    onClick={() => {
                        setViewOtpModal(false);
                        setEdit(null);
                        setEmail("");
                        setSaveEdit("");
                    }}
                >
                    <OtpModal onClick={(e) => e.stopPropagation()}>
                        <TopBox>
                            <Title>Verify OTP</Title>
                            <ResendButtonBox>
                                {otpTimer <= 0 ? (
                                    <ResendButton
                                        onClick={() => {
                                            resendOtp();
                                        }}
                                    >
                                        Resend OTP
                                    </ResendButton>
                                ) : (
                                    <OtpTimer>
                                        Resend in{" "}
                                        <Span>
                                            {title === "Failed!" ? formatTime(otpTimer) : otpTimer}{" "}
                                            Sec
                                        </Span>
                                    </OtpTimer>
                                )}
                            </ResendButtonBox>
                        </TopBox>
                        <Heading>
                            OTP Sent to <span>{email}</span>
                        </Heading>
                        <EmailInput
                            type="number"
                            onChange={handleOtpChange}
                            value={otp}
                            maxLength={4}
                            placeholder={
                                screenWidth > 480 ? `Enter OTP Send to ${email}` : `Enter OTP`
                            }
                        />
                        <SubmitButton onClick={() => verifyOTP()}>
                            {loading ? <ButtonLoader /> : "Submit"}
                            <ErrorMessage>{errorMessage}</ErrorMessage>
                        </SubmitButton>
                    </OtpModal>
                </Overlay>
            )}
        </div>
    );
}

const Overlay = styled.section`
    height: 100%;
    width: 100vw;
    background: rgba(188, 188, 188, 0.5);
    display: flex;
    justify-content: center;
    align-content: center;
    position: fixed;
    z-index: 10;
    inset: 0;
`;
const OtpModal = styled.div`
    width: 500px;
    padding: 55px 50px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media all and (max-width: 540px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 85%;
        padding: 25px;
    }
`;
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`;
const Title = styled.span`
    color: #0a0a0a;
`;

const Heading = styled.h4`
    display: none;
    margin: 20px 0 14px;
    font-size: 16px;
    @media all and (max-width: 480px) {
        display: block;
    }
    color: #777a86;
    span {
        color: #626262;
    }
`;
const ResendButtonBox = styled.div`
    color: #3e72e9;
    cursor: pointer;
`;

const ResendButton = styled.h3`
    font-family: "dm_sansboldmedium";
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    color: #e01507;
    width: max-content;

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const OtpTimer = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
    text-align: left;
    color: #777a86;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const Span = styled.span`
    font-size: 16px;
    font-family: "dm_sansregular";
    display: inline-block;
    color: #282c3f;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const SubmitButton = styled.span`
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    border-radius: 5px;
    padding: 10px 30px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 25px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const ErrorMessage = styled.span`
    font-size: 14px;
    color: red;
    position: absolute;
    left: 0;
    top: -20px;
`;

const EmailInput = styled.input`
    border: 1px solid #777a8659;
    border-radius: 5px;
    padding: 14px 12px;
    width: 100%;
    font-family: "dm_sansregular";
    font-size: 14px;
    :focus {
        outline: 1.5px solid #ff5e48;
    }
`;

export default VerifyOtpModal;
