import React from "react";

//packages
import styled from "styled-components";

function EmptyPayement() {
    return (
        <MainContainer>
            <Wrapper className="wrapper">
                <ContentBox>
                    <EmptyPayementImage>
                        <img
                            src={
                                require("../../../assets/images/empty-pages/empty-payement.svg")
                                    .default
                            }
                            alt="empty-cart"
                        />
                    </EmptyPayementImage>
                    <Title>
                        Save Your Cards / UPI while <br /> Doing Payments
                    </Title>
                    <Paragraph>It's convenient to pay with saved cards & UPIs.</Paragraph>
                </ContentBox>
            </Wrapper>
        </MainContainer>
    );
}

export default EmptyPayement;

const MainContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;
    background: #f8f8f8;
    border-radius: 5px;
    overflow: hidden;
`;
const Wrapper = styled.section``;
const ContentBox = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const EmptyPayementImage = styled.div`
    width: 180px;
    margin-bottom: 10px;
    @media all and (max-width: 480px) {
        width: 130px;
    }
`;
const Title = styled.h2`
    font-size: 20px;
    color: #282c3f;
    text-align: center;
    margin-bottom: 7px;
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
    br {
        display: none;
        @media all and (max-width: 415px) {
            display: block;
        }
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin-bottom: 30px;
    color: #777a86;
    font-family: "dm_sansregular";
    @media all and (max-width: 360px) {
        width: 80%;
        text-align: center;
    }
`;
